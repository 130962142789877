<ng-container *ngIf="item">
  <!-- label -->
  <div class="lhc-de-label" (focus)="lhcDataService.setActiveRow(item)">
    <span *ngIf="item._questionRepeatable && !item._horizontalTableHeader && !lhcDataService.isHideRepetitionNumber()" class="lf-sn">{{lhcDataService.getRepeatingSN(item) }}</span>
    <span class="lhc-question">
      <label id="label-{{ item._elementId }}" for="{{item._elementId}}">
        <!-- prefix -->
        <span *ngIf="item.prefix" class="prefix" [style]="item._obj_prefixCSS">{{item.prefix}}</span>
        <!-- question text -->
        <span class="question" [style]="item._obj_textCSS">{{item.question}}
          <!-- required -->
          <span *ngIf="item._answerRequired" class="lhc-required" title="Required">*</span>
        </span>
      </label>
    </span>
    <!-- code -->
    <span
      *ngIf="lhcDataService.getLhcFormData().templateOptions.showQuestionCode && item.codeList && item.codeList.length>0">
      <span *ngFor="let code of item.codeList">
        <span *ngIf="code.code">
          <a *ngIf="code.system == 'http://loinc.org'" href="{{ 'https://loinc.org/' + code.code}}" class="lhc-item-code"
            target="_blank" rel="noopener noreferrer">
            [{{ code.code }}]
          </a>
          <span *ngIf="code.system != 'http://loinc.org'" class="lhc-item-code">[{{ code.code }}]</span>
        </span>
      </span>
    </span>
    <!-- coding instructions / helps -->
    <span [ngSwitch]="lhcDataService.getCodingInstructionsDisplayType(item)" *ngIf="item.codingInstructions">
      <div *ngSwitchCase="'inline-escaped'"
            id="help-{{ item._elementId }}"
            class="lhc-inline-help"
            attr.aria-label="Help for question {{item.question}}"
            attr.aria-describedby="label-{{ item._elementId }}"
       >{{item.codingInstructions}}</div>
      <div *ngSwitchCase="'inline-html'"
            id="help-{{ item._elementId }}"
            class="lhc-inline-help"
            [innerHTML]="item._codingInstructionsWithContainedImages || item.codingInstructions | safeHtml"
            attr.aria-label="Help for question {{item.question}}"
            attr.aria-describedby="label-{{ item._elementId }}"
       ></div>
      <div *ngSwitchCase="'inline-plain'" 
            id="help-{{ item._elementId }}" 
            class="lhc-inline-help"
            attr.aria-label="Help for question {{item.question}}" 
            attr.aria-describedby="label-{{ item._elementId }}"
      >{{item.codingInstructionsPlain}}</div>
      <ng-container *ngSwitchCase="'popover-escaped'">
        <lhc-button-popover [item]="item" [popoverType]="'help-string'"></lhc-button-popover>
       </ng-container>
      <ng-container *ngSwitchCase="'popover-html'">
        <lhc-button-popover [item]="item" [popoverType]="'help-html'"></lhc-button-popover>
      </ng-container>
      <ng-container *ngSwitchCase="'popover-plain'">
        <lhc-button-popover [item]="item" [popoverType]="'help-plain'"></lhc-button-popover>
      </ng-container>
    </span>
    <!-- copyright -->
    <ng-container *ngIf="item.copyrightNotice">
      <lhc-button-popover [item]="item" [popoverType]="'copyright-string'"></lhc-button-popover>
    </ng-container>
  </div>
</ng-container>
