<ng-container *ngIf="item">
  <div class="lhc-item-group-title lhc-de-label-button">
    <div class="lhc-de-label">
      <lhc-item-question-text [item]="item"></lhc-item-question-text>
    </div>
    <div class="lhc-de-button">
      <button *ngIf="!lhcDataService.hasOneRepeatingItem(item)" class="lhc-float-button lhc-button" type="button"
                (click)="lhcDataService.removeOneRepeatingItem(item)" id="del-{{item._elementId}}"
                title='Remove this "{{ item._text }}"'>-</button>
    </div>
  </div>

  <!-- sub items -->
  <div *ngIf="item.items" class="lhc-item-group-body">
    <ng-container *ngFor="let item of item.items; trackBy: lhcDataService.trackByElementId">
      <lhc-item *ngIf="item && lhcDataService.targetShown(item) && !item._isHiddenFromView"
        [item]="item" class="{{lhcDataService.getItemClassList(item, viewMode)}}"
        id="item-{{ item._elementId }}">
      </lhc-item>
    </ng-container>
  </div>

  <!-- a button at the end of each repeating section -->
  <div *ngIf="item._lastRepeatingItem" class="lhc-button-padding {{lhcDataService.getSkipLogicClass(item)}}">
    <lhc-button-popover [item]="item" [popoverType]="'repeating-item'" buttonLabel= '+ {{item._text}}'></lhc-button-popover>
  </div>

</ng-container>

