<ng-container *ngIf="item">
  <nz-radio-group
    [ngModel]="radioValue"
    (ngModelChange)="onModelChange($event)"
    role="radiogroup"
    [nzName]="'radiogroup_' + item._elementId"
    attr.aria-labelledby="label-{{ item._elementId }}"
    (click)="lhcDataService.setActiveRow(item)">
    <label
      nz-radio
      [nzDisabled]="item._readOnly"
      [disabled]="item._readOnly"
      [nzValue]="true"
      [(ngModel)]="booleanModels[0]"
      [id]="item._elementId + 'true'"
      >Yes</label>
    <label
      nz-radio
      [nzDisabled]="item._readOnly"
      [disabled]="item._readOnly"
      [nzValue]="false"
      [(ngModel)]="booleanModels[1]"
      [id]="item._elementId + 'false'"
      >No</label>
    <label
      nz-radio
      [nzDisabled]="item._readOnly"
      [disabled]="item._readOnly"
      [nzValue]="null"
      [(ngModel)]="booleanModels[2]"
      [id]="item._elementId + 'null'"
      >Not Answered</label>
  </nz-radio-group>
</ng-container>
