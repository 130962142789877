<ng-container [ngSwitch]="popoverType">
  <button *ngSwitchCase="'repeating-item'" id="add-{{item._elementId}}" type="button" class="lhc-float-button lhc-button"  
          nz-popover
          [(nzPopoverVisible)]="item._showUnusedItemWarning"
          [nzPopoverContent]="warningStrTemplate"
          nzPopoverPlacement="topLeft"
          [nzPopoverTrigger]="click"
          nzPopoverOverlayClassName="lhc-warning-class{{ item._elementId.replaceAll('/','-') }}"
          (click)="lhcDataService.addOneRepeatingItem(item, append)"
          (blur)="lhcDataService.hideUnusedItemWarning(item)"
          attr.aria-controls="add-button-{{item._elementId}}"
          attr.aria-label="add button for {{item.question}}"
          attr.aria-describedby="add-button-{{ item._elementId }}">              
    {{ buttonLabel }}
  </button>
  <button *ngSwitchCase="'help-string'" id="help-button-{{ item._elementId }}" type="button" class="lhc-help-button"  
          nz-popover
          [nzPopoverContent]="helpStrTemplate"
          nzPopoverTrigger="click" 
          nzPopoverPlacement="right"  
          nzPopoverTitle="Instruction"
          nzPopoverOverlayClassName="lhc-popover-help lhc-help{{ item._elementId.replaceAll('/','-') }}"
          (click)="onShowingPopover(item)"
          attr.aria-controls="help-content-{{item._elementId}}"
          attr.aria-label="Help button for {{item.question}}"
          attr.aria-describedby="help-button-{{ item._elementId }}">              
    <i nz-icon nzType="question-circle" nzTheme="fill"></i>
  </button>
  <button *ngSwitchCase="'help-html'" id="help-button-{{ item._elementId }}" type="button" class="lhc-help-button"  
          nz-popover
          [nzPopoverContent]="helpHtmlTemplate"
          nzPopoverTrigger="click" 
          nzPopoverPlacement="right"  
          nzPopoverTitle="Instruction"
          nzPopoverOverlayClassName="lhc-popover-help lhc-help{{ item._elementId.replaceAll('/','-') }}"
          (click)="onShowingPopover(item)"
          attr.aria-controls="help-content-{{item._elementId}}"
          attr.aria-label="Help button for {{item.question}}"
          attr.aria-describedby="help-button-{{ item._elementId }}">
    <i nz-icon nzType="question-circle" nzTheme="fill"></i>
  </button>
  <button *ngSwitchCase="'help-plain'" id="help-button-{{ item._elementId }}" type="button" class="lhc-help-button"
          nz-popover 
          [nzPopoverContent]="helpPlainTemplate" 
          nzPopoverTrigger="click" 
          nzPopoverPlacement="right"
          nzPopoverTitle="Instruction"
          nzPopoverOverlayClassName="lhc-popover-help lhc-help{{ item._elementId.replaceAll('/','-') }}"
          (click)="onShowingPopover(item)" 
          attr.aria-controls="help-content-{{item._elementId}}"
          attr.aria-label="Help button for {{item.question}}" 
          attr.aria-describedby="help-button-{{ item._elementId }}">
    <i nz-icon nzType="question-circle" nzTheme="fill"></i>
  </button>
  <ng-container *ngSwitchCase="'copyright-string'">
    <button *ngIf="!formLevel" id="copyright-button-{{item._elementId}}" type="button" class="lhc-copyright-button" 
            nz-popover            
            [nzPopoverContent]="copyrightStrTemplate"
            nzPopoverTrigger="click"
            nzPopoverPlacement="right"
            nzPopoverTitle="Copyright"
            nzPopoverOverlayClassName="lhc-popover-copyright lhc-copyright-class{{ item._elementId.replaceAll('/','-') }}"
            (click)="onShowingPopover(item)"
            attr.aria-controls="copyright-content-{{item._elementId}}"
            attr.aria-label="Copyright button for {{item.question}}" 
            attr.aria-describedby="copyright-button-{{ item._elementId }}">
      <i nz-icon nzType="copyright-circle" nzTheme="fill"></i>
    </button>
    <button *ngIf="formLevel" 
            id="copyright-button-{{popoverIdentifier}}" 
            type="button" 
            class="lhc-copyright-button" 
            nz-popover            
            [nzPopoverContent]="copyrightStrTemplate"
            nzPopoverTrigger="click"
            nzPopoverPlacement="right"
            nzPopoverTitle="Copyright"
            nzPopoverOverlayClassName="lhc-popover-copyright lhc-copyright-class{{popoverIdentifier}}"        
            (click)="onShowingPopover(item)"    
            attr.aria-controls="copyright-content-{{popoverIdentifier}}"
            attr.aria-label="Copyright button for {{item.name || item.shortName}}" 
            attr.aria-describedby="copyright-button-{{popoverIdentifier}}">
      <i nz-icon nzType="copyright-circle" nzTheme="outline"></i>
    </button>
  </ng-container>
  
</ng-container>

<!-- unused item warning template -->
<ng-template #warningStrTemplate>
  <span attr.aria-labelledby="add-button-{{item._elementId}}"
        role="region"
        attr.aria-label="{{item._unusedItemWarning}}"
        attr.aria-describedby="add-content-{{item._elementId}}"
        id="add-content-{{item._elementId}}"
  >{{item._unusedItemWarning}}</span>
</ng-template>

<!-- help popover template with trusted html -->
<ng-template #helpHtmlTemplate>
  <span attr.aria-labelledby="help-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Instruction for {{item.question}}"
        attr.aria-describedby="help-content-{{item._elementId}}"
        id="help-content-{{item._elementId}}"
        [innerHTML]="item._codingInstructionsWithContainedImages || item.codingInstructions | safeHtml"
  ></span>
</ng-template>
<!-- help popover template with plain text-->
<ng-template #helpPlainTemplate>
  <span attr.aria-labelledby="help-button-{{item._elementId}}" 
        role="region"
        attr.aria-label="Instruction for {{item.question}}" 
        attr.aria-describedby="help-content-{{item._elementId}}"
        id="help-content-{{item._elementId}}"
  >{{item.codingInstructionsPlain}}</span>
</ng-template>
<!-- help popover template -->
<ng-template #helpStrTemplate>
  <span attr.aria-labelledby="help-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Instruction for {{item.question}}"
        attr.aria-describedby="help-content-{{item._elementId}}"
        id="help-content-{{item._elementId}}"
  >{{item.codingInstructions}}</span>
</ng-template>

<!-- copyright popover template -->
<ng-template #copyrightStrTemplate >
  <span *ngIf="!formLevel" 
        attr.aria-labelledby="copyright-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Copyright notice for {{item.question}}" 
        attr.aria-describedby="copyright-content-{{item._elementId}}"
        id="copyright-content-{{item._elementId}}"
  >{{item.copyrightNotice}}</span>
  <span *ngIf="formLevel"
        id="copyright-content-{{popoverIdentifier}}"
        attr.aria-labelledby="copyright-button-{{popoverIdentifier}}"
        role="region"
        attr.aria-label="Copyright notice for {{item.name || item.shortName}}" 
        attr.aria-describedby="copyright-content-{{popoverIdentifier}}"
        id="copyright-content-{{popoverIdentifier}}"
  >{{item.copyrightNotice}}</span>  
</ng-template>
